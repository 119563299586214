import { LOG_GRAPHQL_REQUEST } from '@/app/_common/graphql/log-graphql-request';
import { split, ApolloClient, InMemoryCache, from, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AuthStore } from '@/app/_common/stores';
import { customRelayStylePagination } from '@/app/_common/graphql/pagination';
import { getReconnectAttempts } from '@/app/_common/utils';
import { StatusCodes } from '../constants';
import { NotificationsStore } from '../stores';
import { createClient } from 'graphql-ws';
import { createLazyLoadableLaikaLink } from '@zendesk/laika';
import type { Laika } from '@zendesk/laika';

declare global {
	interface Window {
		onLaikaReady: (laika: Laika) => void;
	}
}
const UNAUTHENTICATED_CODE = 'UNAUTHENTICATED';

export class GraphqlClient extends ApolloClient<Record<string, unknown>> {
	private wsLink?: WebSocketLink;
	authStore = injectInterface({}, AuthStore);
	notificationsStore = injectInterface({}, NotificationsStore);

	constructor() {
		super({
			link: undefined,
			cache: new InMemoryCache({
				typePolicies: {
					Query: {
						fields: {
							listCollectors: customRelayStylePagination(),
							listAlerts: customRelayStylePagination(),
							listIntegrations: customRelayStylePagination(),
							listEndpoints: customRelayStylePagination(),
							listActionsHistory: customRelayStylePagination(),
							listActionsHistoryForFranchise: customRelayStylePagination(),
							listUsers: customRelayStylePagination(),
							listQueryResults: customRelayStylePagination(),
						},
					},
					UserTenantItem: {
						keyFields: ['id', 'invitedTimestamp', 'invitedBy', 'registrationTimestamp'],
					},
					UserFranchiseInfo: {
						// UserFranchiseInfo entities dont't have unique ID, but have field 'id'. It contains ID of franchise.
						// By default Apollo would treat this field as unique ID to create resource id, but it shouldn't do so. See: XDR-11500
						keyFields: false,
					},
				},
			}),
		});
		this.setConnectionLinks();
	}

	setConnectionLinks() {
		if (this.wsLink) {
			return;
		}

		const batchLink = this.getBatchLink();

		let wsLink: WebSocketLink | ApolloLink;

		if (process.env.REACT_APP_SUBSCRIPTION_ENABLED === 'true') {
			wsLink = new GraphQLWsLink(
				createClient({
					url: process.env.REACT_APP_WEBSOCKET_URL || '',
					connectionParams: () => {
						return {
							Authorization: this.authStore.token,
							'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_TOKEN as string,
						};
					},
					retryAttempts: getReconnectAttempts(process.env.REACT_APP_SUBSCRIPTION_RECONNECT_ATTEMPTS),
				}),
			);
		} else {
			wsLink = ApolloLink.empty();
		}

		const errorLink = this.getErrorLink();

		const splitLink = split(
			({ query }) => {
				const definition = getMainDefinition(query);
				return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
			},
			wsLink,
			batchLink,
		);

		const logLink = new ApolloLink((operation, forward) => {
			return forward(operation).map((response) => {
				LOG_GRAPHQL_REQUEST(operation);

				return response;
			});
		});

		const laikaLink =
			process.env.REACT_APP_INTEGRATION_TESTS === 'true'
				? [
						createLazyLoadableLaikaLink({
							clientName: 'laika',
							startLoggingImmediately: true,
							onLaikaReady: window.onLaikaReady,
						}),
				  ]
				: [];

		Object.defineProperty(this, 'wsLink', {
			value: wsLink,
			enumerable: false,
		});

		this.setLink(from([logLink, errorLink, ...laikaLink, splitLink]));
	}

	reconnectWS() {
		if (this.wsLink) {
			// @ts-ignore - Workaround to replace token
			this.wsLink?.subscriptionClient?.close(false, false);
		}
	}

	private getBatchLink(): BatchHttpLink {
		const customFetch = (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
			const options = init || {};
			options.headers = {
				...options.headers,
				authorization: this.authStore.token,
			};
			return fetch(input, options);
		};

		return new BatchHttpLink({
			uri: process.env.REACT_APP_GRAPHQL_URL,
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': 'true',
				authorization: this.authStore.token,
				'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_TOKEN as string,
			},
			fetch: customFetch,
			batchMax: (process.env.REACT_APP_BATCH_HTTP_LINK_MAX as string as unknown as number) || 10,
			batchInterval: (process.env.REACT_APP_BATCH_HTTP_LINK_INTERVAL as string as unknown as number) || 10,
		});
	}

	private getErrorLink(): ApolloLink {
		return onError(({ graphQLErrors, networkError }) => {
			if (graphQLErrors) {
				for (const err of graphQLErrors) {
					if (err?.extensions?.code === UNAUTHENTICATED_CODE) {
						this.authStore.logout();
					}

					this.notificationsStore.openError({
						title: 'Error',
						content: err?.message,
					});
				}
			}

			if (networkError) {
				if ('statusCode' in networkError && networkError.statusCode === StatusCodes.UNAUTHORIZED) {
					this.authStore.logout();
				}

				if (process.env.REACT_APP_INTEGRATION_TESTS === 'true') {
					return;
				}

				this.notificationsStore.openError({
					title: 'Error',
					content: networkError.message ?? 'Network Error',
				});
			}
		});
	}
}
