export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	Object: any;
};

export type ActionHistoryEdge = {
	__typename?: 'ActionHistoryEdge';
	cursor: Scalars['String'];
	node: Audit;
};

export type ActionsHistoryConnection = {
	__typename?: 'ActionsHistoryConnection';
	edges: Array<ActionHistoryEdge>;
	pageInfo: PageInfo;
};

export type Alert = {
	__typename?: 'Alert';
	data_objects?: Maybe<Array<DataObject>>;
	device_id?: Maybe<Scalars['ID']>;
	event_details?: Maybe<EventDetails>;
	event_source: Scalars['String'];
	externalReferenceUrl?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	investigationSummary?: Maybe<InvestigationSummary>;
	last_updated: Scalars['String'];
	recommendation?: Maybe<Scalars['String']>;
	severity: Severity;
	state?: Maybe<State>;
	summary?: Maybe<Scalars['String']>;
	tenant_id: Scalars['ID'];
	timestamp: Scalars['String'];
	title: Scalars['String'];
};

export type AlertAssignmentCount = {
	__typename?: 'AlertAssignmentCount';
	assigned: Scalars['Int'];
	dismissed: Scalars['Int'];
	unassigned: Scalars['Int'];
};

export type AlertCount = {
	__typename?: 'AlertCount';
	assignment: AlertAssignmentCount;
	detectedBy: AlertDetectedByCount;
	severity: AlertSeverityCount;
	total: Scalars['Int'];
};

export type AlertCountResult = AlertCount | Error;

export type AlertDetectedByCount = {
	__typename?: 'AlertDetectedByCount';
	aiEngine: Scalars['Int'];
	vendor: Scalars['Int'];
};

export type AlertEdge = {
	__typename?: 'AlertEdge';
	cursor: Scalars['String'];
	node: Alert;
};

export type AlertResult = Alert | Error;

export type AlertSeverityCount = {
	__typename?: 'AlertSeverityCount';
	critical: Scalars['Int'];
	high: Scalars['Int'];
	low: Scalars['Int'];
	medium: Scalars['Int'];
	unknown: Scalars['Int'];
};

export enum AlertState {
	Assigned = 'ASSIGNED',
	Dismissed = 'DISMISSED',
}

export type Alerts = {
	__typename?: 'Alerts';
	alerts: Array<Alert>;
};

export type AlertsConnection = {
	__typename?: 'AlertsConnection';
	edges: Array<AlertEdge>;
	pageInfo: PageInfo;
};

export type AppConfiguration = {
	__typename?: 'AppConfiguration';
	featureFlags: Array<FeatureFlag>;
};

export type AppConfigurationResult = AppConfiguration | Error;

export enum ArtifactType {
	Investigation = 'INVESTIGATION',
}

export type ArtifactValuesInput = {
	artifactId: Scalars['String'];
	artifactType: ArtifactType;
};

export type Assignee = {
	__typename?: 'Assignee';
	displayName: Scalars['String'];
	id: Scalars['String'];
};

export type Audit = {
	__typename?: 'Audit';
	action: AuditAction;
	correlationId: Scalars['String'];
	id: Scalars['ID'];
	internalMessage?: Maybe<Scalars['String']>;
	message: Scalars['String'];
	requesterUser?: Maybe<RequesterUser>;
	resourceId: Scalars['ID'];
	resourceType: ResourceType;
	source: Scalars['String'];
	tenantId: Scalars['ID'];
	timestamp: Scalars['String'];
};

export enum AuditAction {
	Create = 'CREATE',
	Deisolate = 'DEISOLATE',
	Delete = 'DELETE',
	Isolate = 'ISOLATE',
	Read = 'READ',
	Update = 'UPDATE',
}

export type BaseUser = {
	displayName?: Maybe<Scalars['String']>;
	firstName?: Maybe<Scalars['String']>;
	hasCompletedRegistration?: Maybe<Scalars['Boolean']>;
	id: Scalars['ID'];
	lastLogin?: Maybe<Scalars['String']>;
	lastName?: Maybe<Scalars['String']>;
	last_updated: Scalars['String'];
	timestamp: Scalars['String'];
	upn: Scalars['String'];
};

export type Billing = {
	__typename?: 'Billing';
	billingPeriod: Scalars['Int'];
	billingPeriodUnit?: Maybe<Scalars['String']>;
	billingRegion?: Maybe<Scalars['String']>;
	currency: Scalars['String'];
	nextBillingDate?: Maybe<Scalars['String']>;
};

export type CancelFranchiseInvitationResult = Error | FranchiseUser;

export type CancelInvitationResult = Error | User;

export type CloseDetails = {
	description?: InputMaybe<Scalars['String']>;
	reason: CloseReason;
};

export enum CloseReason {
	FalsePositive = 'FalsePositive',
	SecurityIncidentConfirmed = 'SecurityIncidentConfirmed',
	TruePositive = 'TruePositive',
}

export type ClosedDetails = {
	__typename?: 'ClosedDetails';
	description?: Maybe<Scalars['String']>;
	reason: CloseReason;
	timestamp: Scalars['String'];
};

export type Collector = EntityWithTimestamps & {
	__typename?: 'Collector';
	completedTimestamp?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	download?: Maybe<DownloadDetails>;
	hostname?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	lastEventTimestamp?: Maybe<Scalars['String']>;
	last_updated: Scalars['String'];
	location?: Maybe<Scalars['String']>;
	name: Scalars['String'];
	networkSettings?: Maybe<NetworkSettings>;
	ntpServers?: Maybe<Array<Maybe<Scalars['String']>>>;
	proxyServerIp?: Maybe<Scalars['String']>;
	state: CollectorState;
	status: CollectorStatus;
	statusReason?: Maybe<Scalars['String']>;
	tenantId: Scalars['ID'];
	timestamp: Scalars['String'];
	type: CollectorType;
};

export type CollectorEdge = {
	__typename?: 'CollectorEdge';
	cursor: Scalars['String'];
	node: Collector;
};

export type CollectorResult = Collector | Error;

export enum CollectorState {
	CollectorComplete = 'COLLECTOR_COMPLETE',
	CollectorCreateComplete = 'COLLECTOR_CREATE_COMPLETE',
	CollectorCreateFailed = 'COLLECTOR_CREATE_FAILED',
	CollectorCreateRequested = 'COLLECTOR_CREATE_REQUESTED',
}

export enum CollectorStatus {
	AdministrativelyDown = 'ADMINISTRATIVELY_DOWN',
	Healthy = 'HEALTHY',
	NotAvailable = 'NOT_AVAILABLE',
	NotHealthy = 'NOT_HEALTHY',
	Offline = 'OFFLINE',
	Provisioning = 'PROVISIONING',
}

export enum CollectorType {
	Cloud = 'CLOUD',
	Local = 'LOCAL',
	SecureSyslog = 'SECURE_SYSLOG',
}

export type CollectorsConnection = {
	__typename?: 'CollectorsConnection';
	edges: Array<CollectorEdge>;
	pageInfo: PageInfo;
};

export enum ComponentType {
	Cloud = 'CLOUD',
	Container = 'CONTAINER',
	Response = 'RESPONSE',
}

export type CompromiseStats = {
	__typename?: 'CompromiseStats';
	compromisedUsersCount: Scalars['Int'];
	compromisedWebsitesCount: Scalars['Int'];
	lastRetrievedTimestamp: Scalars['String'];
	organisationId: Scalars['ID'];
};

export type CompromiseStatsResult = CompromiseStats | Error;

export type CompromiseUserDetails = EntityWithTimestamps & {
	__typename?: 'CompromiseUserDetails';
	compromisedData: Array<Scalars['String']>;
	description: Scalars['String'];
	detail: Scalars['String'];
	id: Scalars['ID'];
	last_updated: Scalars['String'];
	organisationId: Scalars['ID'];
	source: Scalars['String'];
	timestamp: Scalars['String'];
	type: Scalars['String'];
	userId: Scalars['String'];
};

export type CompromiseUserDetailsConnection = {
	__typename?: 'CompromiseUserDetailsConnection';
	edges: Array<CompromiseUserDetailsEdge>;
	pageInfo: PageInfo;
};

export type CompromiseUserDetailsEdge = {
	__typename?: 'CompromiseUserDetailsEdge';
	cursor: Scalars['String'];
	node: CompromiseUserDetails;
};

export type CompromiseUserStats = EntityWithTimestamps & {
	__typename?: 'CompromiseUserStats';
	compromisedWebsitesCount: Scalars['Int'];
	id: Scalars['ID'];
	last_updated: Scalars['String'];
	latestDetectionTimestamp: Scalars['String'];
	organisationId: Scalars['ID'];
	timestamp: Scalars['String'];
	upn: Scalars['String'];
};

export type CompromiseUserStatsConnection = {
	__typename?: 'CompromiseUserStatsConnection';
	edges: Array<CompromiseUserStatsEdge>;
	pageInfo: PageInfo;
};

export type CompromiseUserStatsEdge = {
	__typename?: 'CompromiseUserStatsEdge';
	cursor: Scalars['String'];
	node: CompromiseUserStats;
};

export type Configuration = {
	__typename?: 'Configuration';
	configuration: Scalars['Object'];
	id: ConfigurationType;
};

export type ConfigurationResult = Configuration | Error;

export enum ConfigurationType {
	CountriesList = 'COUNTRIES_LIST',
	RestrictedEmailDomains = 'RESTRICTED_EMAIL_DOMAINS',
}

export type Country = {
	__typename?: 'Country';
	alpha2: Scalars['String'];
	alpha3: Scalars['String'];
	id: Scalars['Int'];
	name: Scalars['String'];
};

export type CreateActionInEndpointsInput = {
	actionType: EndpointActionType;
	comment: Scalars['String'];
	endpointIds: Array<Scalars['ID']>;
};

export type CreateActionInEndpointsResult = EndpointActionElements | Error;

export type CreateIntegrationComponent = {
	configuration?: InputMaybe<Array<CreateIntegrationComponentConfiguration>>;
};

export type CreateIntegrationComponentConfiguration = {
	key: Scalars['String'];
	value: Scalars['String'];
};

export type CreateIntegrationInput = {
	collectorId?: InputMaybe<Scalars['String']>;
	components?: InputMaybe<Array<CreateIntegrationComponent>>;
	definitionId: Scalars['String'];
	description?: InputMaybe<Scalars['String']>;
	name: Scalars['String'];
	type: IntegrationType;
};

export type CreateNoteInput = {
	artifactId: Scalars['String'];
	artifactType: ArtifactType;
	noteContent: Scalars['String'];
};

export type DataObject = {
	__typename?: 'DataObject';
	data_path: DataPath;
	data_source: Scalars['String'];
	empty: Scalars['Boolean'];
	mime_type: Scalars['String'];
	object_id?: Maybe<Scalars['String']>;
	object_type: Scalars['String'];
	timestamp: Scalars['String'];
};

export type DataPath = {
	__typename?: 'DataPath';
	container: Scalars['String'];
	key: Scalars['String'];
};

export type DeleteNoteInput = {
	artifactId: Scalars['String'];
	artifactType: ArtifactType;
	id: Scalars['ID'];
};

export type DeletedResourceResult = Error | FranchiseUser | Integration | Note | QueryResult | User;

export type DownloadDetails = {
	__typename?: 'DownloadDetails';
	iso?: Maybe<Iso>;
	timestamp?: Maybe<Scalars['String']>;
};

export type Endpoint = {
	__typename?: 'Endpoint';
	action?: Maybe<EndpointAction>;
	currentStatus: EndpointActionType;
	externalReference: Scalars['String'];
	hostname: Scalars['String'];
	id: Scalars['ID'];
	last_updated: Scalars['String'];
	networkInformation?: Maybe<EndpointNetworkInformation>;
	operatingSystem?: Maybe<Scalars['String']>;
	osVendor?: Maybe<Scalars['String']>;
	responseIntegration: ResponseIntegration;
	statistics?: Maybe<EndpointStatistics>;
	tenantId: Scalars['String'];
	timestamp: Scalars['String'];
};

export type EndpointAction = {
	__typename?: 'EndpointAction';
	comment: Scalars['String'];
	last_updated: Scalars['String'];
	requestor: RequesterUser;
	status: EndpointStatus;
	timestamp: Scalars['String'];
	type: EndpointActionType;
};

export type EndpointActionElements = {
	__typename?: 'EndpointActionElements';
	endpoints: Array<Endpoint>;
};

export enum EndpointActionType {
	Deisolate = 'DEISOLATE',
	Isolate = 'ISOLATE',
}

export type EndpointEdge = {
	__typename?: 'EndpointEdge';
	cursor: Scalars['String'];
	node: Endpoint;
};

export type EndpointIpAddress = {
	__typename?: 'EndpointIpAddress';
	ipAddress?: Maybe<Scalars['String']>;
	macAddress?: Maybe<Scalars['String']>;
	operationalStatus?: Maybe<Scalars['String']>;
	type?: Maybe<Scalars['String']>;
};

export type EndpointNetworkInformation = {
	__typename?: 'EndpointNetworkInformation';
	ipAddresses?: Maybe<Array<EndpointIpAddress>>;
	lastExternalIpAddress?: Maybe<Scalars['String']>;
	lastIpAddress?: Maybe<Scalars['String']>;
};

export type EndpointResult = Endpoint | Error;

export type EndpointStatistics = {
	__typename?: 'EndpointStatistics';
	firstSeenTimestamp?: Maybe<Scalars['String']>;
	lastSeenTimestamp?: Maybe<Scalars['String']>;
};

export enum EndpointStatus {
	Cancelled = 'CANCELLED',
	Failed = 'FAILED',
	InProgress = 'IN_PROGRESS',
	Pending = 'PENDING',
	Succeeded = 'SUCCEEDED',
	TimeOut = 'TIME_OUT',
}

export type EndpointsConnection = {
	__typename?: 'EndpointsConnection';
	edges: Array<EndpointEdge>;
	pageInfo: PageInfo;
};

export type EndpointsTableFilterValue = {
	__typename?: 'EndpointsTableFilterValue';
	count: Scalars['Int'];
	value: Scalars['String'];
};

export type EndpointsTableFilterValues = {
	__typename?: 'EndpointsTableFilterValues';
	filters: Array<EndpointsTableFilterValue>;
};

export type EndpointsTableFilterValuesResult = EndpointsTableFilterValues | Error;

export type EndpointsTableHeadersCount = {
	__typename?: 'EndpointsTableHeadersCount';
	currentStatus: Scalars['Int'];
	hostname: Scalars['Int'];
	lastIpAddress: Scalars['Int'];
	operatingSystem: Scalars['Int'];
	product: Scalars['Int'];
	responseIntegration: Scalars['Int'];
	total: Scalars['Int'];
	vendor: Scalars['Int'];
};

export type EndpointsTableHeadersCountResult = EndpointsTableHeadersCount | Error;

export type EntityWithTimestamps = {
	id: Scalars['ID'];
	last_updated: Scalars['String'];
	timestamp: Scalars['String'];
};

export type EnvironmentVariable = {
	__typename?: 'EnvironmentVariable';
	defaultValue?: Maybe<Scalars['String']>;
	description?: Maybe<Scalars['String']>;
	displayName: Scalars['String'];
	enumValues?: Maybe<Array<Scalars['String']>>;
	maxLength?: Maybe<Scalars['Int']>;
	maxValue?: Maybe<Scalars['Int']>;
	minLength?: Maybe<Scalars['Int']>;
	minValue?: Maybe<Scalars['Int']>;
	name: Scalars['String'];
	required: VariableRequired;
	type: EnvironmentVariableType;
};

export enum EnvironmentVariableType {
	Boolean = 'BOOLEAN',
	Enum = 'ENUM',
	Integer = 'INTEGER',
	Password = 'PASSWORD',
	String = 'STRING',
	Username = 'USERNAME',
}

export type Error = {
	__typename?: 'Error';
	additionalInformation?: Maybe<Array<ErrorAdditionalInformation>>;
	code: ErrorCode;
	correlationId?: Maybe<Scalars['String']>;
	message: Scalars['String'];
};

export type ErrorAdditionalInformation = {
	__typename?: 'ErrorAdditionalInformation';
	name: Scalars['String'];
	value: Scalars['String'];
};

export enum ErrorCode {
	Forbidden = 'FORBIDDEN',
	NotFound = 'NOT_FOUND',
	UnexpectedError = 'UNEXPECTED_ERROR',
	ValidationFailure = 'VALIDATION_FAILURE',
}

export type EventDetails = {
	__typename?: 'EventDetails';
	confidence?: Maybe<Scalars['String']>;
	dst?: Maybe<Array<Maybe<Scalars['String']>>>;
	dst_port?: Maybe<Array<Maybe<Scalars['String']>>>;
	killchain_state?: Maybe<Scalars['String']>;
	mitre_category?: Maybe<Scalars['String']>;
	src?: Maybe<Array<Maybe<Scalars['String']>>>;
	src_port?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FeatureFlag = {
	__typename?: 'FeatureFlag';
	enabled: Scalars['Boolean'];
	key: Scalars['String'];
};

export type FieldSort = {
	direction: SortDirection;
	field: Scalars['String'];
};

export type FieldTimestampRange = {
	field: TimestampFilterField;
	range: TimestampRange;
};

export type FieldValue = {
	field: Scalars['String'];
	operator: ValueOperator;
	value?: InputMaybe<Scalars['String']>;
	values?: InputMaybe<Array<Scalars['String']>>;
};

export type FiltersInput = {
	timestampFilters?: InputMaybe<Array<FieldTimestampRange>>;
	valueFilters?: InputMaybe<Array<FieldValue>>;
};

export type FranchiseUser = BaseUser &
	EntityWithTimestamps & {
		__typename?: 'FranchiseUser';
		displayName?: Maybe<Scalars['String']>;
		firstName?: Maybe<Scalars['String']>;
		franchise: UserFranchiseInfo;
		hasCompletedRegistration?: Maybe<Scalars['Boolean']>;
		id: Scalars['ID'];
		invitedBy?: Maybe<Scalars['String']>;
		invitedTimestamp?: Maybe<Scalars['String']>;
		lastLogin?: Maybe<Scalars['String']>;
		lastName?: Maybe<Scalars['String']>;
		last_updated: Scalars['String'];
		registrationTimestamp?: Maybe<Scalars['String']>;
		timestamp: Scalars['String'];
		upn: Scalars['String'];
	};

export type FranchiseUserEdge = {
	__typename?: 'FranchiseUserEdge';
	cursor: Scalars['String'];
	node: FranchiseUser;
};

export type FranchiseUsersConnection = {
	__typename?: 'FranchiseUsersConnection';
	edges: Array<FranchiseUserEdge>;
	pageInfo: PageInfo;
};

export type Integration = EntityWithTimestamps & {
	__typename?: 'Integration';
	collector?: Maybe<IntegrationCollector>;
	completedTimestamp?: Maybe<Scalars['String']>;
	components?: Maybe<Array<IntegrationComponent>>;
	definitionId?: Maybe<Scalars['ID']>;
	description?: Maybe<Scalars['String']>;
	hostname?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	lastEventTimestamp?: Maybe<Scalars['String']>;
	last_updated: Scalars['String'];
	name?: Maybe<Scalars['String']>;
	productName?: Maybe<Scalars['String']>;
	sourceType: IntegrationSourceType;
	status: IntegrationStatus;
	statusReason?: Maybe<Scalars['String']>;
	tenantId: Scalars['ID'];
	timestamp: Scalars['String'];
	type: IntegrationType;
	vendor: Scalars['String'];
};

export type IntegrationBaseComponentDefinition = {
	description?: Maybe<Scalars['String']>;
	environments?: Maybe<Array<EnvironmentVariable>>;
	type: ComponentType;
};

export type IntegrationCollector = {
	__typename?: 'IntegrationCollector';
	hostname?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	ipAddress?: Maybe<Scalars['String']>;
	location?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
	type: CollectorType;
};

export type IntegrationComponent = {
	__typename?: 'IntegrationComponent';
	configuration?: Maybe<Array<IntegrationComponentConfiguration>>;
};

export type IntegrationComponentConfiguration = {
	__typename?: 'IntegrationComponentConfiguration';
	key: Scalars['String'];
	value: Scalars['String'];
};

export type IntegrationComponentDefinition = IntegrationBaseComponentDefinition & {
	__typename?: 'IntegrationComponentDefinition';
	description?: Maybe<Scalars['String']>;
	environments?: Maybe<Array<EnvironmentVariable>>;
	externalRef?: Maybe<Scalars['String']>;
	type: ComponentType;
};

export type IntegrationDefinition = EntityWithTimestamps & {
	__typename?: 'IntegrationDefinition';
	abstract?: Maybe<Scalars['String']>;
	categories?: Maybe<Array<Scalars['String']>>;
	collectorType: CollectorType;
	components?: Maybe<Array<IntegrationComponentDefinition>>;
	documentationUrl?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	isResponse: Scalars['Boolean'];
	isTelemetry: Scalars['Boolean'];
	last_updated: Scalars['String'];
	product: Scalars['String'];
	responseActions?: Maybe<Array<Scalars['String']>>;
	responseComponents?: Maybe<Array<IntegrationResponseComponentDefinition>>;
	state: Scalars['String'];
	stateReason?: Maybe<Scalars['String']>;
	timestamp: Scalars['String'];
	vendor: Vendor;
};

export type IntegrationDefinitionEdge = {
	__typename?: 'IntegrationDefinitionEdge';
	cursor: Scalars['String'];
	node: IntegrationDefinition;
};

export type IntegrationDefinitionResult = Error | IntegrationDefinition;

export type IntegrationDefinitionsConnection = {
	__typename?: 'IntegrationDefinitionsConnection';
	edges: Array<IntegrationDefinitionEdge>;
	pageInfo: PageInfo;
};

export type IntegrationEdge = {
	__typename?: 'IntegrationEdge';
	cursor: Scalars['String'];
	node: Integration;
};

export type IntegrationResponseComponentDefinition = IntegrationBaseComponentDefinition & {
	__typename?: 'IntegrationResponseComponentDefinition';
	description?: Maybe<Scalars['String']>;
	environments?: Maybe<Array<EnvironmentVariable>>;
	type: ComponentType;
};

export type IntegrationResult = Error | Integration;

export enum IntegrationSourceType {
	AutoDetected = 'AUTO_DETECTED',
	UserCreated = 'USER_CREATED',
}

export enum IntegrationStatus {
	AdministrativelyDown = 'ADMINISTRATIVELY_DOWN',
	Healthy = 'HEALTHY',
	NotAvailable = 'NOT_AVAILABLE',
	NotHealthy = 'NOT_HEALTHY',
	Provisioning = 'PROVISIONING',
}

export enum IntegrationType {
	Response = 'RESPONSE',
	Telemetry = 'TELEMETRY',
}

export type IntegrationsConnection = {
	__typename?: 'IntegrationsConnection';
	edges: Array<IntegrationEdge>;
	pageInfo: PageInfo;
};

export type Investigation = EntityWithTimestamps & {
	__typename?: 'Investigation';
	alerts?: Maybe<Array<InvestigationAlertSummary>>;
	assignee?: Maybe<Assignee>;
	closedDetails?: Maybe<ClosedDetails>;
	id: Scalars['ID'];
	last_updated: Scalars['String'];
	name: Scalars['String'];
	priority: InvestigationPriority;
	queryResults?: Maybe<Array<InvestigationQueryResultSummary>>;
	sleepingDetails?: Maybe<SleepingDetails>;
	status: InvestigationStatus;
	tenantId: Scalars['ID'];
	timestamp: Scalars['String'];
	type: InvestigationType;
};

export type InvestigationAlertSummary = {
	__typename?: 'InvestigationAlertSummary';
	addedTimestamp: Scalars['String'];
	confidence?: Maybe<Scalars['String']>;
	dst?: Maybe<Array<Scalars['String']>>;
	event_source?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	mitre_category?: Maybe<Scalars['String']>;
	name: Scalars['String'];
	severity: Severity;
	src?: Maybe<Array<Scalars['String']>>;
	state?: Maybe<State>;
	timestamp: Scalars['String'];
	type?: Maybe<Scalars['String']>;
};

export type InvestigationEdge = {
	__typename?: 'InvestigationEdge';
	cursor: Scalars['String'];
	node: Investigation;
};

export enum InvestigationPriority {
	Critical = 'Critical',
	High = 'High',
	Low = 'Low',
	Medium = 'Medium',
}

export type InvestigationQueryResultSummary = {
	__typename?: 'InvestigationQueryResultSummary';
	alertCount: Scalars['Int'];
	eventCount: Scalars['Int'];
	evidenceCount: Scalars['Int'];
	id: Scalars['ID'];
};

export type InvestigationResult = Error | Investigation;

export enum InvestigationStatus {
	Closed = 'Closed',
	Open = 'Open',
	Sleep = 'Sleep',
}

export type InvestigationSummary = {
	__typename?: 'InvestigationSummary';
	id: Scalars['ID'];
};

export enum InvestigationType {
	Alert = 'Alert',
	Incident = 'Incident',
	Threat = 'Threat',
}

export type InvestigationsConnection = {
	__typename?: 'InvestigationsConnection';
	edges: Array<InvestigationEdge>;
	pageInfo: PageInfo;
};

export type InviteFranchiseUserResult = Error | FranchiseUser;

export type InviteUserResult = Error | User;

export type Invoice = {
	__typename?: 'Invoice';
	dueDate: Scalars['String'];
	invoiceId: Scalars['ID'];
	issueDate: Scalars['String'];
	status: Scalars['String'];
};

export enum IpAddressType {
	Dhcp = 'DHCP',
	Static = 'STATIC',
}

export type Iso = {
	__typename?: 'Iso';
	key: Scalars['String'];
	name: Scalars['String'];
};

export type ListNotesResult = Error | NotesConnection;

export type Mutation = {
	__typename?: 'Mutation';
	assignQueryResultToInvestigation: InvestigationResult;
	cancelFranchiseInvitation: CancelFranchiseInvitationResult;
	cancelInvitation: CancelInvitationResult;
	createActionInEndpoints: CreateActionInEndpointsResult;
	createIntegration: IntegrationResult;
	createInvestigation: InvestigationResult;
	createNote: NoteResult;
	deleteIntegration: DeletedResourceResult;
	deleteNote: DeletedResourceResult;
	inviteFranchiseUser: InviteFranchiseUserResult;
	inviteUser: InviteUserResult;
	removeFranchiseUser: DeletedResourceResult;
	removeUser: DeletedResourceResult;
	resendFranchiseInvitation: ResendFranchiseInvitationResult;
	resendInvitation: ResendInvitationResult;
	setAlertsOnInvestigation: InvestigationResult;
	setAlertsState: SetAlertsStateResult;
	signupTenant: SignupTenantResult;
	unassignQueryResultFromInvestigation: InvestigationResult;
	updateInvestigation: InvestigationResult;
	updateNote: NoteResult;
	updateQueryResult: QueryResultResult;
	validateIntegrationParameters?: Maybe<Error>;
};

export type MutationAssignQueryResultToInvestigationArgs = {
	alertIds?: InputMaybe<Array<Scalars['ID']>>;
	investigationId: Scalars['ID'];
	queryResultInput: QueryResultInput;
	tenantId: Scalars['ID'];
};

export type MutationCancelFranchiseInvitationArgs = {
	franchiseId: Scalars['ID'];
	userId: Scalars['String'];
};

export type MutationCancelInvitationArgs = {
	tenantId: Scalars['ID'];
	userId: Scalars['String'];
};

export type MutationCreateActionInEndpointsArgs = {
	createActionInEndpointsRequest: CreateActionInEndpointsInput;
	tenantId: Scalars['ID'];
};

export type MutationCreateIntegrationArgs = {
	createIntegrationRequest: CreateIntegrationInput;
	tenantId: Scalars['ID'];
};

export type MutationCreateInvestigationArgs = {
	alertIds?: InputMaybe<Array<Scalars['ID']>>;
	assigneeId?: InputMaybe<Scalars['ID']>;
	name: Scalars['String'];
	priority: InvestigationPriority;
	queryResultInput?: InputMaybe<QueryResultInput>;
	tenantId: Scalars['ID'];
	type: InvestigationType;
};

export type MutationCreateNoteArgs = {
	createNoteRequest: CreateNoteInput;
	tenantId: Scalars['ID'];
};

export type MutationDeleteIntegrationArgs = {
	id: Scalars['ID'];
	tenantId: Scalars['ID'];
};

export type MutationDeleteNoteArgs = {
	deleteNoteRequest?: InputMaybe<DeleteNoteInput>;
	tenantId: Scalars['ID'];
};

export type MutationInviteFranchiseUserArgs = {
	email: Scalars['String'];
	franchiseId: Scalars['ID'];
};

export type MutationInviteUserArgs = {
	email: Scalars['String'];
	tenantId: Scalars['ID'];
};

export type MutationRemoveFranchiseUserArgs = {
	franchiseId: Scalars['ID'];
	userId: Scalars['ID'];
};

export type MutationRemoveUserArgs = {
	tenantId: Scalars['ID'];
	userId: Scalars['ID'];
};

export type MutationResendFranchiseInvitationArgs = {
	email: Scalars['String'];
	franchiseId: Scalars['ID'];
};

export type MutationResendInvitationArgs = {
	email: Scalars['String'];
	tenantId: Scalars['ID'];
};

export type MutationSetAlertsOnInvestigationArgs = {
	alertIds: Array<Scalars['ID']>;
	investigationId: Scalars['ID'];
	tenantId: Scalars['ID'];
};

export type MutationSetAlertsStateArgs = {
	action: SetAlertsStateAction;
	alertIds: Array<Scalars['String']>;
	tenantId: Scalars['ID'];
};

export type MutationSignupTenantArgs = {
	franchiseId: Scalars['ID'];
	signupTenantInput: SignupTenantInput;
};

export type MutationUnassignQueryResultFromInvestigationArgs = {
	investigationId: Scalars['ID'];
	queryResultId: Scalars['ID'];
	tenantId: Scalars['ID'];
};

export type MutationUpdateInvestigationArgs = {
	tenantId: Scalars['ID'];
	updateInvestigation: UpdateInvestigation;
};

export type MutationUpdateNoteArgs = {
	tenantId: Scalars['ID'];
	updateNote: UpdateNoteInput;
};

export type MutationUpdateQueryResultArgs = {
	tenantId: Scalars['ID'];
	updateQueryResultInput: UpdateQueryResultInput;
};

export type MutationValidateIntegrationParametersArgs = {
	integrationParameters: ValidateIntegrationParametersInput;
	tenantId: Scalars['ID'];
};

export type NetworkSettings = {
	__typename?: 'NetworkSettings';
	hostname?: Maybe<Scalars['String']>;
	ipAddress?: Maybe<Scalars['String']>;
	ipAddressType?: Maybe<IpAddressType>;
	portNumber?: Maybe<Scalars['Int']>;
	staticIpDetails?: Maybe<StaticIpDetails>;
};

export type Note = EntityWithTimestamps & {
	__typename?: 'Note';
	artifactId: Scalars['String'];
	artifactType: ArtifactType;
	id: Scalars['ID'];
	last_updated: Scalars['String'];
	noteContent: Scalars['String'];
	requesterUser?: Maybe<RequesterUser>;
	tenantId: Scalars['ID'];
	timestamp: Scalars['String'];
	updatedBy: Array<Maybe<UpdatedBy>>;
};

export type NoteEdge = {
	__typename?: 'NoteEdge';
	cursor: Scalars['String'];
	node: Note;
};

export type NoteResult = Error | Note;

export type NotesConnection = {
	__typename?: 'NotesConnection';
	edges: Array<NoteEdge>;
	pageInfo: PageInfo;
};

export type OptionallyRequired = {
	__typename?: 'OptionallyRequired';
	name: Scalars['String'];
	value: Scalars['String'];
};

export type OwnerUser = {
	__typename?: 'OwnerUser';
	id: Scalars['ID'];
	upn: Scalars['String'];
};

export type PageInfo = {
	__typename?: 'PageInfo';
	endCursor?: Maybe<Scalars['String']>;
	hasNextPage: Scalars['Boolean'];
	hasPreviousPage: Scalars['Boolean'];
	startCursor?: Maybe<Scalars['String']>;
};

export type Paging = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

export type Query = {
	__typename?: 'Query';
	getAlert: AlertResult;
	getAlertCount: AlertCountResult;
	getAppConfiguration: AppConfigurationResult;
	getCollector: CollectorResult;
	getCompromiseStats: CompromiseStatsResult;
	getCompromiseStatsForFranchise: CompromiseStatsResult;
	getConfiguration: ConfigurationResult;
	getEndpoint: EndpointResult;
	getEndpointsTableHeadersCount: EndpointsTableHeadersCountResult;
	getIntegration: IntegrationResult;
	getIntegrationDefinition: IntegrationDefinitionResult;
	getInvestigation: InvestigationResult;
	getNote: NoteResult;
	getTenantSubscriptionDetails: TenantSubscriptionDetailsResult;
	listActionsHistory: ActionsHistoryConnection;
	listActionsHistoryForFranchise: ActionsHistoryConnection;
	listAlerts: AlertsConnection;
	listCollectors: CollectorsConnection;
	listCompromiseUserDetails: CompromiseUserDetailsConnection;
	listCompromiseUserDetailsForFranchise: CompromiseUserDetailsConnection;
	listCompromiseUserStats: CompromiseUserStatsConnection;
	listCompromiseUserStatsForFranchise: CompromiseUserStatsConnection;
	listEndpoints: EndpointsConnection;
	listEndpointsTableFilterValues: EndpointsTableFilterValuesResult;
	listFranchiseUsers: FranchiseUsersConnection;
	listIntegrationDefinitions: IntegrationDefinitionsConnection;
	listIntegrations: IntegrationsConnection;
	listInvestigations: InvestigationsConnection;
	listNotes: ListNotesResult;
	listQueryResults: QueryResultConnection;
	listTenants: TenantsConnection;
	listUsers: UsersConnection;
};

export type QueryGetAlertArgs = {
	id: Scalars['ID'];
	tenantId: Scalars['ID'];
};

export type QueryGetAlertCountArgs = {
	from: Scalars['String'];
	tenantId: Scalars['ID'];
	to: Scalars['String'];
};

export type QueryGetAppConfigurationArgs = {
	tenantId: Scalars['ID'];
};

export type QueryGetCollectorArgs = {
	id: Scalars['ID'];
	tenantId: Scalars['ID'];
};

export type QueryGetCompromiseStatsArgs = {
	tenantId: Scalars['ID'];
};

export type QueryGetCompromiseStatsForFranchiseArgs = {
	franchiseId: Scalars['ID'];
};

export type QueryGetConfigurationArgs = {
	configurationType: ConfigurationType;
};

export type QueryGetEndpointArgs = {
	id: Scalars['ID'];
	tenantId: Scalars['ID'];
};

export type QueryGetEndpointsTableHeadersCountArgs = {
	filtersInput?: InputMaybe<FiltersInput>;
	tenantId: Scalars['ID'];
};

export type QueryGetIntegrationArgs = {
	id: Scalars['ID'];
	tenantId: Scalars['ID'];
};

export type QueryGetIntegrationDefinitionArgs = {
	id: Scalars['ID'];
	tenantId: Scalars['ID'];
};

export type QueryGetInvestigationArgs = {
	id: Scalars['ID'];
	tenantId: Scalars['ID'];
};

export type QueryGetNoteArgs = {
	artifactId: Scalars['ID'];
	artifactType: ArtifactType;
	id: Scalars['ID'];
	tenantId: Scalars['ID'];
};

export type QueryGetTenantSubscriptionDetailsArgs = {
	tenantId: Scalars['ID'];
};

export type QueryListActionsHistoryArgs = {
	filtersInput?: InputMaybe<FiltersInput>;
	input?: InputMaybe<Paging>;
	sortersInput?: InputMaybe<SortersInput>;
	tenantId: Scalars['ID'];
};

export type QueryListActionsHistoryForFranchiseArgs = {
	filtersInput?: InputMaybe<FiltersInput>;
	franchiseId: Scalars['ID'];
	input?: InputMaybe<Paging>;
	sortersInput?: InputMaybe<SortersInput>;
};

export type QueryListAlertsArgs = {
	filtersInput?: InputMaybe<FiltersInput>;
	input?: InputMaybe<Paging>;
	tenantId: Scalars['ID'];
};

export type QueryListCollectorsArgs = {
	filtersInput?: InputMaybe<FiltersInput>;
	input?: InputMaybe<Paging>;
	sortersInput?: InputMaybe<SortersInput>;
	tenantId: Scalars['ID'];
};

export type QueryListCompromiseUserDetailsArgs = {
	filtersInput?: InputMaybe<FiltersInput>;
	input?: InputMaybe<Paging>;
	sortersInput?: InputMaybe<SortersInput>;
	tenantId: Scalars['ID'];
};

export type QueryListCompromiseUserDetailsForFranchiseArgs = {
	filtersInput?: InputMaybe<FiltersInput>;
	franchiseId: Scalars['ID'];
	input?: InputMaybe<Paging>;
	sortersInput?: InputMaybe<SortersInput>;
};

export type QueryListCompromiseUserStatsArgs = {
	filtersInput?: InputMaybe<FiltersInput>;
	input?: InputMaybe<Paging>;
	sortersInput?: InputMaybe<SortersInput>;
	tenantId: Scalars['ID'];
};

export type QueryListCompromiseUserStatsForFranchiseArgs = {
	filtersInput?: InputMaybe<FiltersInput>;
	franchiseId: Scalars['ID'];
	input?: InputMaybe<Paging>;
	sortersInput?: InputMaybe<SortersInput>;
};

export type QueryListEndpointsArgs = {
	filtersInput?: InputMaybe<FiltersInput>;
	input?: InputMaybe<Paging>;
	sortersInput?: InputMaybe<SortersInput>;
	tenantId: Scalars['ID'];
};

export type QueryListEndpointsTableFilterValuesArgs = {
	column: Scalars['String'];
	filtersInput?: InputMaybe<FiltersInput>;
	tenantId: Scalars['ID'];
};

export type QueryListFranchiseUsersArgs = {
	filtersInput?: InputMaybe<FiltersInput>;
	franchiseId: Scalars['ID'];
	input?: InputMaybe<Paging>;
};

export type QueryListIntegrationDefinitionsArgs = {
	filtersInput?: InputMaybe<FiltersInput>;
	input?: InputMaybe<Paging>;
	sortersInput?: InputMaybe<SortersInput>;
	tenantId: Scalars['ID'];
};

export type QueryListIntegrationsArgs = {
	filtersInput?: InputMaybe<FiltersInput>;
	input?: InputMaybe<Paging>;
	sortersInput?: InputMaybe<SortersInput>;
	tenantId: Scalars['ID'];
};

export type QueryListInvestigationsArgs = {
	filtersInput?: InputMaybe<FiltersInput>;
	input?: InputMaybe<Paging>;
	sortersInput?: InputMaybe<SortersInput>;
	tenantId: Scalars['ID'];
};

export type QueryListNotesArgs = {
	filtersInput: FiltersInput;
	input?: InputMaybe<Paging>;
	tenantId: Scalars['ID'];
};

export type QueryListQueryResultsArgs = {
	filtersInput?: InputMaybe<FiltersInput>;
	input?: InputMaybe<Paging>;
	sortersInput?: InputMaybe<SortersInput>;
	tenantId: Scalars['ID'];
};

export type QueryListTenantsArgs = {
	filtersInput?: InputMaybe<FiltersInput>;
	franchiseId: Scalars['ID'];
	input?: InputMaybe<Paging>;
};

export type QueryListUsersArgs = {
	filtersInput?: InputMaybe<FiltersInput>;
	input?: InputMaybe<Paging>;
	tenantId: Scalars['ID'];
};

export type QueryResult = EntityWithTimestamps & {
	__typename?: 'QueryResult';
	id: Scalars['ID'];
	investigationId: Scalars['ID'];
	last_updated: Scalars['String'];
	mitre?: Maybe<Array<Scalars['String']>>;
	query: Scalars['String'];
	reason?: Maybe<Scalars['String']>;
	requesterUser?: Maybe<RequesterUser>;
	tenantId: Scalars['ID'];
	timestamp: Scalars['String'];
	updatedBy: Array<UpdatedBy>;
};

export type QueryResultConnection = {
	__typename?: 'QueryResultConnection';
	edges: Array<QueryResultEdge>;
	pageInfo: PageInfo;
};

export type QueryResultEdge = {
	__typename?: 'QueryResultEdge';
	cursor: Scalars['String'];
	node: QueryResult;
};

export type QueryResultInput = {
	alertCount: Scalars['Int'];
	eventCount: Scalars['Int'];
	evidenceCount: Scalars['Int'];
	mitre?: InputMaybe<Array<Scalars['String']>>;
	query: Scalars['String'];
	reason?: InputMaybe<Scalars['String']>;
};

export type QueryResultResult = Error | QueryResult;

export type RequesterUser = {
	__typename?: 'RequesterUser';
	id: Scalars['String'];
	upn: Scalars['String'];
};

export type ResendFranchiseInvitationResult = Error | FranchiseUser;

export type ResendInvitationResult = Error | User;

export enum ResourceType {
	Alert = 'ALERT',
	Collector = 'COLLECTOR',
	Email = 'EMAIL',
	Endpoint = 'ENDPOINT',
	Franchise = 'FRANCHISE',
	Integration = 'INTEGRATION',
	Investigation = 'INVESTIGATION',
	Note = 'NOTE',
	QueryResult = 'QUERY_RESULT',
	Tenant = 'TENANT',
	User = 'USER',
}

export type ResponseIntegration = {
	__typename?: 'ResponseIntegration';
	id: Scalars['ID'];
	name: Scalars['String'];
	productName: Scalars['String'];
	vendor: Scalars['String'];
};

export enum SetAlertsStateAction {
	Dismiss = 'DISMISS',
	Undismiss = 'UNDISMISS',
}

export type SetAlertsStateResult = Alerts | Error;

export enum Severity {
	Critical = 'Critical',
	High = 'High',
	Low = 'Low',
	Medium = 'Medium',
	Unknown = 'Unknown',
}

export type SignupTenant = {
	__typename?: 'SignupTenant';
	country: Scalars['String'];
	email: Scalars['String'];
	externalId?: Maybe<Scalars['ID']>;
	firstName: Scalars['String'];
	lastName: Scalars['String'];
	tenantId?: Maybe<Scalars['String']>;
	tenantName?: Maybe<Scalars['String']>;
};

export type SignupTenantInput = {
	country: Scalars['String'];
	email: Scalars['String'];
	externalId?: InputMaybe<Scalars['ID']>;
	firstName: Scalars['String'];
	lastName: Scalars['String'];
	name: Scalars['String'];
};

export type SignupTenantResult = Error | SignupTenant;

export type SleepDetails = {
	description?: InputMaybe<Scalars['String']>;
	endTimestamp?: InputMaybe<Scalars['String']>;
};

export type SleepingDetails = {
	__typename?: 'SleepingDetails';
	description?: Maybe<Scalars['String']>;
	endTimestamp?: Maybe<Scalars['String']>;
	startTimestamp: Scalars['String'];
};

export enum SortDirection {
	Asc = 'ASC',
	Desc = 'DESC',
}

export type SortersInput = {
	fieldSorters?: InputMaybe<Array<FieldSort>>;
};

export type State = {
	__typename?: 'State';
	alertState: AlertState;
	requesterUser?: Maybe<RequesterUser>;
	timestamp: Scalars['String'];
};

export type StaticIpDetails = {
	__typename?: 'StaticIpDetails';
	alternateDnsServerIpv4: Scalars['String'];
	defaultGatewayIpv4: Scalars['String'];
	primaryDnsServerIpv4: Scalars['String'];
	subnetMaskIpv4: Scalars['String'];
};

export type StaticIpDetailsInput = {
	alternateDnsServerIpv4: Scalars['String'];
	defaultGatewayIpv4: Scalars['String'];
	ipAddressIpv4: Scalars['String'];
	primaryDnsServerIpv4: Scalars['String'];
	subnetMaskIpv4: Scalars['String'];
};

export type Subscription = {
	__typename?: 'Subscription';
	alertCreated: Alert;
	alertUpdated: Alert;
	collectorUpdated: Collector;
	endpointCreated: Endpoint;
	endpointUpdated: Endpoint;
	integrationCreated: Integration;
	integrationUpdated: Integration;
	investigationCreated: Investigation;
	investigationUpdated: Investigation;
	noteCreated: Note;
	noteUpdated: Note;
	queryResultCreated: QueryResult;
	queryResultUpdated: QueryResult;
	resourceDeleted: DeletedResourceResult;
	tenantSubscriptionDetailsUpdated: TenantSubscriptionDetails;
	validateIntegrationParametersResult: ValidateIntegrationParametersResult;
};

export type SubscriptionAlertCreatedArgs = {
	tenantId: Scalars['ID'];
};

export type SubscriptionAlertUpdatedArgs = {
	tenantId: Scalars['ID'];
};

export type SubscriptionCollectorUpdatedArgs = {
	tenantId: Scalars['ID'];
};

export type SubscriptionEndpointCreatedArgs = {
	tenantId: Scalars['ID'];
};

export type SubscriptionEndpointUpdatedArgs = {
	tenantId: Scalars['ID'];
};

export type SubscriptionIntegrationCreatedArgs = {
	tenantId: Scalars['ID'];
};

export type SubscriptionIntegrationUpdatedArgs = {
	tenantId: Scalars['ID'];
};

export type SubscriptionInvestigationCreatedArgs = {
	tenantId: Scalars['ID'];
};

export type SubscriptionInvestigationUpdatedArgs = {
	tenantId: Scalars['ID'];
};

export type SubscriptionNoteCreatedArgs = {
	tenantId: Scalars['ID'];
};

export type SubscriptionNoteUpdatedArgs = {
	tenantId: Scalars['ID'];
};

export type SubscriptionQueryResultCreatedArgs = {
	investigationId: Scalars['ID'];
	tenantId: Scalars['ID'];
};

export type SubscriptionQueryResultUpdatedArgs = {
	investigationId: Scalars['ID'];
	tenantId: Scalars['ID'];
};

export type SubscriptionResourceDeletedArgs = {
	tenantId: Scalars['ID'];
};

export type SubscriptionTenantSubscriptionDetailsUpdatedArgs = {
	tenantId: Scalars['ID'];
};

export type SubscriptionValidateIntegrationParametersResultArgs = {
	tenantId: Scalars['ID'];
	trackingId: Scalars['ID'];
};

export type SubscriptionDetails = {
	__typename?: 'SubscriptionDetails';
	id?: Maybe<Scalars['ID']>;
	purchaseDate?: Maybe<Scalars['String']>;
	status?: Maybe<Scalars['String']>;
	termEndDate?: Maybe<Scalars['String']>;
	termStartDate?: Maybe<Scalars['String']>;
	trialEndDate?: Maybe<Scalars['String']>;
	trialStartDate?: Maybe<Scalars['String']>;
};

export type SubscriptionItem = {
	__typename?: 'SubscriptionItem';
	name: Scalars['String'];
	purchasedQuantity: Scalars['Int'];
	type: Scalars['String'];
};

export type SubscriptionTenant = {
	__typename?: 'SubscriptionTenant';
	id: Scalars['ID'];
	name: Scalars['String'];
};

export type Tenant = {
	__typename?: 'Tenant';
	hasCompletedRegistration: Scalars['Boolean'];
	id: Scalars['ID'];
	name: Scalars['String'];
	ownerUser: OwnerUser;
	registeredTimestamp?: Maybe<Scalars['String']>;
	timestamp: Scalars['String'];
};

export type TenantEdge = {
	__typename?: 'TenantEdge';
	cursor: Scalars['String'];
	node: Tenant;
};

export type TenantResult = Error | Tenant;

export type TenantSubscriptionDetails = {
	__typename?: 'TenantSubscriptionDetails';
	billing?: Maybe<Billing>;
	invoice?: Maybe<Invoice>;
	subscription?: Maybe<SubscriptionDetails>;
	subscriptionItems?: Maybe<Array<SubscriptionItem>>;
	tenant: SubscriptionTenant;
};

export type TenantSubscriptionDetailsResult = Error | TenantSubscriptionDetails;

export type TenantsConnection = {
	__typename?: 'TenantsConnection';
	edges: Array<TenantEdge>;
	pageInfo: PageInfo;
};

export enum TimestampFilterField {
	CompletedTimestamp = 'completedTimestamp',
	LastUpdated = 'last_updated',
	Timestamp = 'timestamp',
}

export type TimestampRange = {
	from: Scalars['String'];
	to: Scalars['String'];
};

export type UpdateInvestigation = {
	assigneeId?: InputMaybe<Scalars['ID']>;
	id: Scalars['ID'];
	name?: InputMaybe<Scalars['String']>;
	priority?: InputMaybe<InvestigationPriority>;
	status?: InputMaybe<UpdateStatusType>;
	type?: InputMaybe<InvestigationType>;
};

export type UpdateNoteInput = {
	artifactId: Scalars['String'];
	artifactType: ArtifactType;
	id: Scalars['ID'];
	noteContent: Scalars['String'];
};

export type UpdateQueryResultInput = {
	id: Scalars['ID'];
	investigationId: Scalars['ID'];
	mitre?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	reason?: InputMaybe<Scalars['String']>;
};

export type UpdateStatusType = {
	closeDetails?: InputMaybe<CloseDetails>;
	sleepDetails?: InputMaybe<SleepDetails>;
	status: InvestigationStatus;
};

export type UpdatedBy = {
	__typename?: 'UpdatedBy';
	requesterUser?: Maybe<RequesterUser>;
	timestamp: Scalars['String'];
};

export type User = BaseUser &
	EntityWithTimestamps & {
		__typename?: 'User';
		displayName?: Maybe<Scalars['String']>;
		firstName?: Maybe<Scalars['String']>;
		hasCompletedRegistration?: Maybe<Scalars['Boolean']>;
		id: Scalars['ID'];
		inviteExpirationTimestamp?: Maybe<Scalars['String']>;
		lastLogin?: Maybe<Scalars['String']>;
		lastName?: Maybe<Scalars['String']>;
		last_updated: Scalars['String'];
		role: Scalars['String'];
		tenants?: Maybe<Array<UserTenantItem>>;
		timestamp: Scalars['String'];
		upn: Scalars['String'];
	};

export type UserEdge = {
	__typename?: 'UserEdge';
	cursor: Scalars['String'];
	node: User;
};

export type UserFranchiseInfo = {
	__typename?: 'UserFranchiseInfo';
	id: Scalars['ID'];
	isOwner: Scalars['Boolean'];
};

export type UserTenantItem = {
	__typename?: 'UserTenantItem';
	hasFreshdeskCompanyAssigned: Scalars['Boolean'];
	id: Scalars['String'];
	invitedBy?: Maybe<Scalars['String']>;
	invitedTimestamp?: Maybe<Scalars['String']>;
	isBillingUser: Scalars['Boolean'];
	registrationTimestamp?: Maybe<Scalars['String']>;
	role: Scalars['String'];
};

export type UsersConnection = {
	__typename?: 'UsersConnection';
	edges: Array<UserEdge>;
	pageInfo: PageInfo;
};

export type ValidateIntegrationParametersInput = {
	components?: InputMaybe<Array<CreateIntegrationComponent>>;
	definitionId: Scalars['ID'];
	integrationType: IntegrationType;
	trackingId: Scalars['ID'];
};

export type ValidateIntegrationParametersResult = {
	__typename?: 'ValidateIntegrationParametersResult';
	code?: Maybe<ErrorCode>;
	message?: Maybe<Scalars['String']>;
	success: Scalars['Boolean'];
	tenantId: Scalars['ID'];
	trackingId: Scalars['ID'];
};

export enum ValueOperator {
	Is = 'IS',
	IsIn = 'IS_IN',
	IsNot = 'IS_NOT',
	IsNotIn = 'IS_NOT_IN',
	IsPresent = 'IS_PRESENT',
}

export type VariableRequired = {
	__typename?: 'VariableRequired';
	mandatory: Scalars['Boolean'];
	optionally?: Maybe<Array<OptionallyRequired>>;
};

export type Vendor = {
	__typename?: 'Vendor';
	logoFileName: Scalars['String'];
	name: Scalars['String'];
	url: Scalars['String'];
};
