export enum RootPaths {
	ADVANCED_QUERY = '/advanced-query',
	ALERTS_DASHBOARDS = '/dashboards/alerts-dashboard',
	COLLECTORS = '/telemetry/collectors',
	CREATE_RESPONSE_INTEGRATION = '/response/integrations/create-integration',
	CREATE_TELEMETRY_INTEGRATION = '/telemetry/integrations/create-integration',
	CREATE_FRANCHISE_CLIENT = '/franchise/clients/create-client',
	DASHBOARDS = '/dashboards',
	EXPIRED_TOKEN = '/expiredToken',
	FRANCHISE = '/franchise',
	FRANCHISE_ACTION_HISTORY = '/franchise/action-history',
	FRANCHISE_CLIENTS = '/franchise/clients',
	FRANCHISE_TEAM = '/franchise/team',
	INTEGRATION_CONFIGURATION = '/telemetry/collectors/:collectorId/integrations/:integrationId',
	INTEGRATION_DELETE = '/telemetry/integrations/delete/:integrationId/collectors/:collectorId',
	INTEGRATION_DELETE_IN_COLLECTOR = '/telemetry/collectors/:collectorId/integrations/delete/:integrationId',
	INVESTIGATION_DETAILS = '/investigations/:id',
	INVESTIGATIONS = '/investigations',
	LOGIN = '/login',
	MANAGEMENT = '/management',
	MANAGEMENT_ACTION_HISTORY = '/management/action-history',
	MANAGEMENT_USERS = '/management/users',
	PAGE_NOT_ACCESS = '/pageNotAccess',
	PAGE_NOT_FOUND = '/pageNotFound',
	RESPONSE = '/response',
	RESPONSE_ENDPOINTS = '/response/endpoints',
	RESPONSE_INTEGRATION_DETAILS = '/response/integrations/:integrationId',
	RESPONSE_INTEGRATIONS = '/response/integrations',
	SIGN_OUT = '/signout',
	SUBSCRIPTION = '/subscription',
	TELEMETRY = '/telemetry',
	TELEMETRY_DASHBOARD = '/dashboards/telemetry-dashboard',
	TELEMETRY_INTEGRATION_DETAILS = '/telemetry/integrations/:integrationId',
	TELEMETRY_INTEGRATIONS = '/telemetry/integrations',
	VERIFICATION = '/verifyToken',
	TOOLS = '/tools',
	COMPROMISE_MONITOR = '/tools/compromiseMonitor',
}
