import { ReactElement } from 'react';

import classNames from 'classnames';

import { Place } from 'react-tooltip';
import InfoIcon from '@mui/icons-material/Info';

import { Tooltip } from '@/app/_common/_components';

import styles from './info-tooltip.module.scss';

interface InfoTooltipProps {
	id: string;
	description?: string | ReactElement;
	place?: Place;
	iconClassName?: string;
	tooltipClassName?: string;
	offsetRight?: number;
}

export const InfoTooltip = ({ id, description, place, iconClassName = '', tooltipClassName = '', offsetRight }: InfoTooltipProps) => {
	return (
		<>
			<span data-tip data-for={id} className={classNames(styles.icon, iconClassName)}>
				<InfoIcon />
			</span>
			<Tooltip
				tooltipId={id}
				tooltipContent={description}
				place={place}
				effect={'solid'}
				className={classNames(styles.tooltip, tooltipClassName)}
				offsetRight={offsetRight}
			/>
		</>
	);
};
